/** @jsx jsx */
import { Box, Grid, jsx } from "theme-ui";
import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import BlockLink from "../components/BlockLink";
import SEO from "../components/Seo";
//in graphcms the collection is link singular
// markup
const LinksPage = ({ data }) => {
  const bannerImage = getImage(data.graphCmsPagesLink.featuredImage);
  const links = data.graphCmsPagesLink.blockLinks;

  return (
    <Layout>
      <SEO title="Contact" />
      <GatsbyImage image={bannerImage} alt="" />
      <Box sx={styles}>
        <h1>{data.graphCmsPagesLink.title}</h1>
        <div
          className="bodyText"
          dangerouslySetInnerHTML={{
            __html: data.graphCmsPagesLink.bodyText.html,
          }}
        />
        <Grid gap={3} columns={[2, 3, 4]}>
          {links.map((link, index) => {
            return <BlockLink key={`link-${index}`} link={link} />;
          })}
        </Grid>
      </Box>
    </Layout>
  );
};

export default LinksPage;

export const query = graphql`
  query LinksPageQuery {
    graphCmsPagesLink(
      id: { eq: "PagesLink:cksj4nq281h2d0a02cif3j7hh:PUBLISHED" }
    ) {
      id
      title
      featuredImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: DOMINANT_COLOR
          aspectRatio: 1.97
        )
        alt
      }
      blockLinks {
        title
        url
        logo {
          gatsbyImageData
          alt
        }
      }
      bodyText {
        raw
        html
      }
    }
  }
`;

const styles = {
  mx: [3, 5],
  "& .bodyText": {
    fontSize: 3,
    mb: 4,
    a: {
      color: "house1",
      //px: 2,
      "&:hover": {
        bg: "house4",
        color: "white",
        textDecoration: "none",
      },
    },
    table: {
      borderSpacing: "10px 1em",

      td: {
        maxWidth: "40%",
      },
    },
  },
};
