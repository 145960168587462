/** @jsx jsx */
import { Box, jsx } from "theme-ui";
import React from "react";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

const BlockLink = (props) => {
  const logo = props.link.logo;
  let image, imageAlt;
  if (logo) {
    image = getImage(logo);
    imageAlt = logo.alt;
  }
  return (
    <Box sx={styles}>
      <a href={props.link.url}>
        {logo ? (
          <GatsbyImage image={image} alt={logo.alt} className="linkImage" />
        ) : (
          <StaticImage
            src="../images/placeholder-square.png"
            alt=""
            layout="constrained"
            className="linkImage"
          />
        )}
        <p>{props.link.title}</p>
      </a>
    </Box>
  );
};
export default BlockLink;

const styles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
  a: {
    color: "house1",
    p: {
      textAlign: "center",
    },
    "&:hover, &:focus-within": {
      p: {
        bg: "house1",
        color: "white",
      },
      "& .linkImage": {
        border: (theme) => `2px solid ${theme.colors.house1}`,
      },
    },
  },
  "& .linkImage": {
    maxHeight: "250px",
    maxWidth: "250px",
    border: "none",
  },
};
